<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>family-mother</title>
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="6.759" cy="3.75" r="3" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.509,12a8.339,8.339,0,0,0-1.23-2.52L6.759,12,4.239,9.48A8.385,8.385,0,0,0,3.009,12L.759,18.75h3l.75,4.5h4.5l.75-4.5h3Z"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="15.009" cy="10.5" r="2.25" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.009,14.25A3.75,3.75,0,0,1,18.759,18v.75h-1.5l-.75,4.5h-3l-.25-1.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M22.69,1.339a1.9,1.9,0,0,0-2.752,0L18.759,2.558,17.581,1.339a1.9,1.9,0,0,0-2.752,0,2.06,2.06,0,0,0,0,2.845l3.512,3.631a.578.578,0,0,0,.837,0L22.69,4.184A2.062,2.062,0,0,0,22.69,1.339Z"
    />
  </svg>
</template>
